<template>
    <!-- 导航 -->
    <page-head title="精英球员" />
    <content-bg>
        <template #content>
            <div v-if="playerList.length" class="wrapper" :class="playerList[0].playerPosition">
                <div class="title">
                    {{
                        $utils.getValueByKey(
                            playerPositionList,
                            playerList[0].playerPosition,
                            'label',
                            'value'
                        )
                    }}
                </div>
                <div class="player_list">
                    <div v-for="(item) in playerList" :key="item.id" class="player_item">
                        <div class="avatar_box">
                            <img class="player-avatar" :src="$utils.defaultAvatar(item.avatar)" />
                            <i class="bg"></i>
                        </div>
                        <div class="player_info">
                            <div class="content">
                                <p class="name">{{ item.name }}</p>
                                <p class="nameEn">{{ item.nameEn }}</p>
                                <div class="number">{{ item.number }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <no-data v-else></no-data>
        </template>
    </content-bg>
    <!-- 翻页 -->
    <pagination-com
        v-if="playerList.length > 0"
        class="pagination"
        :current-page="pageInfo.pageNum"
        :total-page="playerAllList.length"
        @changePage="changePage"
    />
</template>

<script>
import { defineComponent, reactive, toRefs, getCurrentInstance, onMounted } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
    name: 'elitePlayers',
    setup() {
        const { proxy } = getCurrentInstance();
        const store = useStore();
        const state = reactive({
            playerAllList: [],
            playerList: [],
            pageInfo: {
                pageNum: 1,
                pageSize: 12,
            },
            playerPositionList: store.state.playerPositionList,
        });

        const getPlayerList = () => {
            const { id } = store.state.teamData;
            const playerPositionList = store.state.playerPositionList;
            state.playerAllList = [];
            proxy.$server.getElites({ teensCampId: id }).then((res) => {
                if (res.code === 200 && res.data) {
                    let info = res.data;
                    // 根据球员位置，分割出想要的数据[[],[],[]]
                    for (let i = 0; i < playerPositionList.length; i++) {
                        let key = playerPositionList[i].value;
                        if (info[key] && info[key].length) {
                            let arr = sliceArr(info[key], state.pageInfo.pageSize);
                            state.playerAllList.push(...arr);
                        }
                    }
                    // 当前页的数据
                    state.playerList = state.playerAllList[state.pageInfo.pageNum - 1];
                }
            });
        };

        // 页码发生变化时
        const changePage = (page) => {
            state.pageInfo.pageNum = page;
            state.playerList = state.playerAllList[state.pageInfo.pageNum - 1];
        };

        // 按位数分割数据
        const sliceArr = (arr, len) => {
            let newArr = [];
            for (let i = 0; i < arr.length; ) {
                newArr.push(arr.slice(i, (i += len)));
            }
            return newArr;
        };

        onMounted(() => {
            getPlayerList();
        });

        return {
            ...toRefs(state),
            changePage,
        };
    },
});
</script>

<style lang="scss" scoped>
::v-deep.pagination {
    position: absolute;
    left: 592px;
    top: 978px;
}
.wrapper {
    &.forward {
        .title {
            background-image: url(~@i/position/forward.svg);
        }
        .name,
        .nameEn {
            color: #003987;
        }
        .bg {
            background: linear-gradient(90deg, #2D64FF 0%, rgba(45, 100, 255, 0) 100%);
        }
    }
    &.midfielder {
        .title {
            background-image: url(~@i/position/midfielder.svg);
        }
        .name,
        .nameEn {
            color: #2f0087;
        }
        .bg {
            background: linear-gradient(90deg, #a049eb 0%, rgba(160, 73, 235, 0) 100%) !important;
        }
    }
    &.defender {
        .title {
            background-image: url(~@i/position/defender.svg);
        }
        .name,
        .nameEn {
            color: #00553b;
        }
        .bg {
            background: linear-gradient(90deg, #00b066 0%, rgba(0, 176, 102, 0) 100%) !important;
        }
    }
    &.goalkeeper {
        .title {
            background-image: url(~@i/position/goalkeeper.svg);
        }
        .name,
        .nameEn {
            color: #551900;
        }
        .bg {
            background: linear-gradient(90deg, #ff9533 0%, rgba(255, 149, 51, 0) 100%) !important;
        }
    }
}
.title {
    height: 70px;
    margin: 30px 0;
    line-height: 70px;
    font-size: 32px;
    color: #ffffff;
    text-align: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.player_list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 40px;
    margin-right: -20px;
}
.player_item {
    font-size: 0;
    transform: skewX(-6deg);
    margin-right: 15px;
    margin-bottom: 30px;
    .avatar_box {
        width: 200px;
        height: 200px;
        overflow: hidden;
        position: relative;
        background-color: #ffffff;
        .player-avatar {
            width: 100%;
            transform: skewX(6deg) scale(1.1);
            margin-left: -2px;
        }
        .bg {
            position: absolute;
            left: 0;
            bottom: 0;
            display: inline-block;
            width: 100%;
            height: 20px;
            background: linear-gradient(90deg, #2d64ff 0%, rgba(45, 100, 255, 0) 100%);
        }
    }
    .player_info {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 200px;
        height: 75px;
        padding: 0 20px;
        background: linear-gradient(90deg, #ffffff 0%, #c6daff 100%);
        position: relative;
        .content {
            transform: skewX(6deg);
        }
        .name,
        .nameEn {
            max-width: 80%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .name {
            font-size: 22px;
        }
        .nameEn {
            font-size: 16px;
            opacity: 0.4;
        }
        .number {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            line-height: 59px;
            background: linear-gradient(180deg, #112c5d 0%, rgba(17, 44, 93, 0) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            font-family: 'akrobatRegular';
            font-size: 48px;
            opacity: 0.4;
        }
    }
}
</style>
